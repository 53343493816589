import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Vikhe Patil Foundation Boys Hostel",
  address: "Ahmednagar, Maharashtra",
  client: "Padmashree Dr. Vithalrao Vikhe Patil Foundation",
  area: "1,00,000 Sq. Ft.",
  project: "Hostel",
  category: "Residential | Architecture",
  status: "Completed",
  backlink: "/projects/residential/",
};

export default class Projectvikhepatilfoundationboyshostel extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Residential Projects | Vikhe Patil Foundation Boys Hostel"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the dedicated residential building designed and developed for a leading educational institute in Pune."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
